import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import {
  FdrB2bForgotPassword,
  FdrB2bLoginForm,
  FdrB2bLoginLayout,
} from '@hotelplan/fdr.regular.fusion.fdr-b2b-auth-forms';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { trackB2bLogin } from 'components/domain/tracking/b2bTracking';

export { getServerSideProps } from 'components/page/b2b-login/b2b-login.gssp';

interface IB2BLoginPageProps {
  mainTitle?: string;
  mainText?: string;
}

const B2BLoginPage: NextPage<IB2BLoginPageProps> = ({
  mainText,
  mainTitle,
}) => {
  const [
    isForgotPasswordOverlayShown,
    openForgotPasswordOverlay,
    closeForgotPasswordOverlay,
  ] = useToggleState(false);
  const [t, { language }] = useTranslation(['forms']);

  const onLogin = useCallback(
    ({ username }) => {
      trackB2bLogin(language, username);
    },
    [language]
  );

  return (
    <>
      <FdrB2bLoginLayout mainTitle={mainTitle} mainText={mainText}>
        <FdrB2bLoginForm
          onForgotPasswordClick={openForgotPasswordOverlay}
          onLogin={onLogin}
        />
      </FdrB2bLoginLayout>
      <FdrB2bForgotPassword
        forgotPasswordLabel={t('forms:agentCode.forgotPassword.label')}
        isShown={isForgotPasswordOverlayShown}
        onClose={closeForgotPasswordOverlay}
      />
    </>
  );
};

export default B2BLoginPage;
