import { track } from '@hotelplan/libs.tracking-events';

export const trackB2bLogin = (language: string, agencyId: string) => {
  track({
    event: 'login' as const,
    action: 'login success' as const,
    label: 'Anmelden' as const,
    category: 'b2b' as const,
    component: 'login-entry-mask',
    position: 'undefined',
    language: language,
    agency_id: agencyId,
    _clear: true,
  });
};
